import { ChangeEvent, FC, Ref, useCallback, useRef, useState } from 'react';
import useSearchGuestlist from '../hooks/useSearchGuestlist';
import { Body, Box, Button, FilterTag, Flex, HStack, IconSearch, Input, Select, useDisclosure } from 'designsystem';
import { FormattedMessage, useIntl } from 'react-intl';
import { StyledIconFilters } from './programme/ProgrammeScheduleFilters';
import { FiltersDrawer, FilterModal, Filter, useRemoveQueryFilters, useSearchState } from 'shared';
import guestlistFilterMessages from '../utils/guestlistFilterMessages';
import { SearchGuestlistFilterQueryParams } from '../utils/searchProgrammeUtils';
import { GuestbookOrderEnum } from '../gql/api';
import { useQueryParams } from 'use-query-params';

const GuestlistFilters: FC = () => {
    const searchState = useSearchState(SearchGuestlistFilterQueryParams);
    const { totalHits, activeFilters, allFilters, orderBy, setOrderBy, itemsPerPage } = useSearchGuestlist(searchState);

    const { query, inputRef, setPage, setQuery } = searchState;
    const [, setQueryParams] = useQueryParams();
    const { formatMessage } = useIntl();
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        // prevent input field lagging
        inputRef.current.value = e.target.value;
        setQuery(e.target.value);
        setPage(1);
    };

    const drawerProps = useDisclosure();
    const modalProps = useDisclosure();
    const [selectedFilter, setSelectedFilter] = useState(null);
    const onModal = useCallback(
        (filter: Filter) => {
            setSelectedFilter(filter);
            modalProps.onOpen();
        },
        [modalProps]
    );
    const removeQueryFilters = useRemoveQueryFilters(SearchGuestlistFilterQueryParams);
    const selectRef: Ref<HTMLSelectElement> = useRef();

    return (
        <>
            <Flex justifyContent="space-between" flexDir={['column', null, null, 'row']} mb={6}>
                <Box h="100%" minW={['auto', null, null, '472px']}>
                    <Input
                        defaultValue={query}
                        ref={inputRef}
                        py={3}
                        px={7}
                        borderRadius="100px"
                        border="1.5px solid"
                        placeholder={formatMessage({ defaultMessage: 'Zoek een gast of bedrijf' })}
                        onChange={handleChange}
                        rightElement={<IconSearch />}
                    />
                </Box>
                <Box mt={[6, null, null, 0]}>
                    <Button
                        ml="auto"
                        w={['100%', null, null, 'auto']}
                        rightIcon={<StyledIconFilters />}
                        onClick={() => drawerProps.onOpen()}
                    >
                        Filters
                    </Button>
                </Box>
            </Flex>
            <Box w="100%" mb={7}>
                {activeFilters.length > 0 && (
                    <HStack mt={6} flexWrap="wrap" spacing={2}>
                        {activeFilters.map(({ key, value }) => (
                            <>
                                {value.map(val => (
                                    <FilterTag
                                        key={val}
                                        onClear={() => {
                                            setQueryParams({
                                                [key]: value.filter(v => v !== val),
                                            });
                                            setPage(1);
                                        }}
                                    >
                                        {val}
                                    </FilterTag>
                                ))}
                            </>
                        ))}
                        {activeFilters.length > 0 && (
                            <FilterTag
                                onClear={() => {
                                    removeQueryFilters();
                                    setPage(1);
                                }}
                            >
                                <FormattedMessage defaultMessage="Alles wissen" />
                            </FilterTag>
                        )}
                    </HStack>
                )}
            </Box>
            <Flex
                justifyContent="space-between"
                alignItems={['start', null, null, 'center']}
                flexDir={['column', null, null, 'row']}
            >
                <Box>
                    {totalHits > 0 && (
                        <Body>
                            <FormattedMessage
                                id="welcome"
                                defaultMessage="{itemCount} van {totalHits} resultaten"
                                values={{ itemCount: itemsPerPage < totalHits ? itemsPerPage : totalHits, totalHits }}
                            />
                        </Body>
                    )}
                </Box>
                <Box justifySelf="flex-end">
                    <Select
                        border="none"
                        id="orderBy"
                        fontWeight={600}
                        background="none"
                        defaultValue={orderBy}
                        ref={selectRef}
                        onChange={e => {
                            setOrderBy(e.currentTarget.value as GuestbookOrderEnum);
                            // orderBy param doesn't update properly without the timeout for the setPage call
                            setTimeout(() => setPage(1), 100);
                            selectRef.current.blur();
                        }}
                    >
                        <option value={GuestbookOrderEnum.FullNameAsc}>
                            <FormattedMessage defaultMessage="Naam (A-Z)" />
                        </option>
                        <option value={GuestbookOrderEnum.CompanyNameAsc}>
                            <FormattedMessage defaultMessage="Bedrijfsnaam (A-Z)" />
                        </option>
                    </Select>
                </Box>
            </Flex>

            <FiltersDrawer
                {...drawerProps}
                queryParams={SearchGuestlistFilterQueryParams}
                filters={allFilters}
                onModal={onModal}
                collectionFilterMessages={guestlistFilterMessages}
                totalHits={totalHits}
                closeLabel={<FormattedMessage defaultMessage="Close" description="Close modal" />}
                modal={
                    <FilterModal
                        {...modalProps}
                        filter={selectedFilter}
                        filterMessages={guestlistFilterMessages}
                        queryParams={SearchGuestlistFilterQueryParams}
                        closeLabel={<FormattedMessage defaultMessage="Close" description="Close modal" />}
                    />
                }
            />
        </>
    );
};

export default GuestlistFilters;
