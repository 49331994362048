import { AtoZFilterEnum } from '../gql/api';
import { defineMessages } from 'react-intl';

const alphabetFilterMessages = defineMessages<AtoZFilterEnum>({
    [AtoZFilterEnum.CountriesOfProduction]: { defaultMessage: 'Productieland' },
    [AtoZFilterEnum.IdfaAwards]: { defaultMessage: 'IDFA Award' },
    [AtoZFilterEnum.LengthInMinutes]: { defaultMessage: 'Lengte' },
    [AtoZFilterEnum.Pathway]: { defaultMessage: 'Wegwijzer' },
    [AtoZFilterEnum.Premiere]: { defaultMessage: 'Premiere' },
    [AtoZFilterEnum.Section]: { defaultMessage: 'Sectie' },
    [AtoZFilterEnum.Tags]: { defaultMessage: 'Onderwerp' },
    [AtoZFilterEnum.AToZType]: { defaultMessage: 'Type' },
    [AtoZFilterEnum.CrossSection]: { defaultMessage: 'Cross Section Awards' },
});

export default alphabetFilterMessages;
