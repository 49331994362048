import { FormattedMessage } from 'react-intl';
import { Heading, HStack, Meta, Note, ProgrammeListItem } from 'designsystem';
import React, { FC, ReactNode, useEffect } from 'react';
import useSearchSchedule from '../../hooks/useSearchSchedule';
import ProgrammeListSection from './ProgrammeListSection';
import ProgrammeListSkeleton from './ProgrammeListSkeleton';
import {
    FormattedDate,
    FormattedDateTimeRange,
    FormattedTimeRange,
    slugifyTitle,
    stripHtml,
    useGetApiImageProps,
    useTickets,
} from 'shared';
import { SearchScheduleHitsQuery } from '../../gql/api';
import useToggleFilmFavorite from '../../hooks/useToggleFilmFavorite';
import useToggleCalendar from '../../hooks/useToggleCalendar';
import ProgrammeScheduleFilters from './ProgrammeScheduleFilters';
import { useRouter } from 'next/router';

const ProgrammeScheduleSearch: FC = () => {
    const { locale } = useRouter();
    const {
        selectFilters,
        checkboxFilters,
        hits,
        hitsLoading,
        totalHits,
        queryFilters,
        drawerFilters,
        activeFilters,
        isTodaySelected,
        soldOutToggle,
        setQueryFilters,
        ongoingHits,
        initialDay,
    } = useSearchSchedule();
    // set default filters during festival
    useEffect(() => {
        if (!document.location.search?.includes('SHOW_TYPE')) {
            setQueryFilters({
                SHOW_TYPE: [locale === 'nl' ? 'Publiek' : 'Public'],
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const hasHits = Object.keys(hits ?? {}).length > 0 || ongoingHits?.length > 0;

    return (
        <>
            {initialDay && (
                <>
                    <ProgrammeScheduleFilters
                        selectFilters={selectFilters}
                        drawerFilters={drawerFilters}
                        activeFilters={activeFilters}
                        checkboxFilters={checkboxFilters}
                        setQueryFilters={setQueryFilters}
                        soldOutToggle={soldOutToggle}
                        totalHits={totalHits}
                    />
                    <Heading variant={2} mt={9}>
                        {isTodaySelected ? (
                            <FormattedMessage defaultMessage="Vandaag" />
                        ) : (
                            // FormattedDate causes hydration error w/o suppressHydrationWarning: https://nextjs.org/docs/messages/react-hydration-error#solution-4-using-suppresshydrationwarning
                            <FormattedDate date={new Date(queryFilters.DAY[0])} variant="DATE-MEDIUM" />
                        )}
                    </Heading>
                </>
            )}

            {!hitsLoading && hasHits && (
                <>
                    {ongoingHits.length > 0 && (
                        <ProgrammeScheduleSection
                            title={
                                <HStack spacing={2}>
                                    <FormattedMessage defaultMessage="Doorlopend programma" />
                                </HStack>
                            }
                            hits={ongoingHits}
                        />
                    )}

                    {Object.keys(hits).map(hour => (
                        <ProgrammeScheduleSection key={hour} title={hour} hits={hits[hour] ?? []} />
                    ))}
                </>
            )}
            {!hitsLoading && !hasHits && (
                <Note mt={[6, null, null, 13]}>
                    <FormattedMessage defaultMessage="Geen shows gevonden voor de geselecteerde datum" />
                </Note>
            )}

            {hitsLoading && <ProgrammeListSkeleton />}
        </>
    );
};

const ProgrammeScheduleSection: FC<{ title: ReactNode; hits: SearchScheduleHitsQuery['searchSchedule']['hits'] }> = ({
    hits,
    title,
}) => {
    const getImgProps = useGetApiImageProps();
    const { toggleFavorite, isFavorited, isLoadingFavorites } = useToggleFilmFavorite();
    const { toggleCalendar, isAddedToCalendar, isLoadingCalendar } = useToggleCalendar();
    const { onOpenTicket, isLoadingTicket } = useTickets();
    return (
        <ProgrammeListSection>
            <Heading variant={4}>{title}</Heading>
            {hits.length > 0 &&
                hits.map(hit => (
                    <ProgrammeListItem
                        key={hit.id}
                        name={hit.fullTitle}
                        meta={
                            <Meta
                                size="s"
                                items={[
                                    {
                                        key: 'show-time',
                                        value: hit.isVideoOnDemand ? (
                                            <FormattedDateTimeRange
                                                from={new Date(hit.validFrom ?? hit.startOn)}
                                                to={new Date(hit.validUntil ?? hit.endOn)}
                                                dateVariant="DATE-LONG"
                                                timeVariant="TIME-SHORT"
                                            />
                                        ) : (
                                            <FormattedTimeRange
                                                from={new Date(hit.startOn)}
                                                to={new Date(hit.endOn)}
                                                variant="TIME-SHORT"
                                            />
                                        ),
                                    },
                                    hit.location && {
                                        key: 'location',
                                        value: hit.location,
                                    },
                                ].filter(Boolean)}
                            />
                        }
                        tags={hit.audience && [{ key: hit.audience.key, value: hit.audience.translation }]}
                        description={stripHtml(
                            hit.intro?.translation ??
                                hit.film?.intro?.translation ??
                                hit.composition?.intro?.translation
                        )}
                        image={getImgProps(
                            hit.film?.publications?.favoriteImage ??
                                hit.composition?.publications?.favoriteImage ??
                                hit.composition?.publications?.stills[0],
                            hit.fullTitle
                        )}
                        href={
                            hit.composition
                                ? `/composition/${hit.composition.id}/${slugifyTitle(hit.composition.fullTitle)}`
                                : hit.film
                                ? `/film/${hit.film.id}/${slugifyTitle(hit.film.fullPreferredTitle)}`
                                : '#'
                        }
                        isLoadingTicket={isLoadingTicket}
                        externalTicketLink={hit.externalSaleLink}
                        onTicketClick={
                            hit.ticketAvailabilityStatus !== 'SOLD_OUT' &&
                            !hit.noSale &&
                            (() =>
                                onOpenTicket({
                                    title: hit.fullTitle,
                                    ticketId: hit.id,
                                    ticketType: 'show',
                                }))
                        }
                        soldOut={hit.ticketAvailabilityStatus === 'SOLD_OUT'}
                        showCalendarIcon
                        toggleCalendar={() => toggleCalendar(hit.id)}
                        isLoadingCalendar={isLoadingCalendar}
                        addedToCalendar={isAddedToCalendar(hit.id)}
                        showFavoriteIcon={hit.film !== null}
                        isLoadingFavorite={isLoadingFavorites}
                        toggleFavorite={() => toggleFavorite(hit.film.id)}
                        isFavorited={isFavorited(hit.film?.id)}
                    />
                ))}
        </ProgrammeListSection>
    );
};

export default ProgrammeScheduleSearch;
