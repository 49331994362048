import { GetStaticPaths, GetStaticProps } from 'next';
import Error from 'next/error';
import { FC, useEffect } from 'react';
import merge from 'deepmerge';
import {
    ContentPagePathsQuery,
    ContentPagePathsQueryVariables,
    ContentPagesQuery,
    ContentPagesQueryVariables,
    useContentPagePathsQuery,
    useContentPagesQuery,
} from '../gql/cms';
import {
    fetchCraftData,
    gtm,
    LoadingContentPageTemplate,
    prefetchContentBlocks,
    PreviewData,
    useParentPage,
} from 'shared';
import loadIntlMessages from '../utils/loadIntlMessages';
import siteHandleByLocale, { getLocaleBySiteHandle } from '../utils/siteHandleByLocale';
import { dehydrate, QueryClient } from '@tanstack/react-query';
import prefetchGlobals from '../utils/prefetchGlobals';
import { useRouter } from 'next/router';
import InformationPage from '../components/pages/InformationPage';
import NavigationPage from '../components/pages/NavigationPage';
import NewsArticlesOverviewPage, {
    getStaticProps as getNewsOverviewStaticProps,
} from '../components/pages/NewsArticlesOverviewPage';
import ProgrammeOverviewPage, {
    getStaticProps as getProgrammeOverviewStaticProps,
} from '../components/pages/ProgrammeOverviewPage';
import GuestlistOverviewPage, {
    getStaticProps as getGuestlistStaticProps,
} from '../components/pages/GuestlistOverviewPage';
import PressAndIndustryCollectionPage, {
    getStaticProps as getPressAndIndustryCollectionStaticProps,
} from '../components/pages/PressAndIndustryCollectionPage';
import ProgrammeCollectionPage, {
    getStaticProps as getProgrammeCollectionPageStaticProps,
} from '../components/pages/ProgrammeCollectionPage';

export const DEFAULT_REVALIDATION_TIME = 60 * 5;

const CatchAll: FC = () => {
    const { query, locale, isFallback } = useRouter();
    const uri = [...(query?.uri ?? [])].join('/');
    const site = siteHandleByLocale[locale];
    const { data: contentData } = useContentPagesQuery(
        {
            uri,
            site,
            search: getRedirectSearch(uri),
        },
        {
            enabled: !isFallback,
        }
    );
    const entry = contentData?.entry;
    const { parentPagePath } = useParentPage();
    const parentPagePathWithoutParams = parentPagePath.split(/[?#]/)[0];
    const pageViewUri = Array.isArray(query.uri) ? query.uri.join('/') : query.uri;

    useEffect(() => {
        gtm.pageView(`/${pageViewUri}`, {
            page_pillar: 'festival',
            page_navigation: parentPagePathWithoutParams,
            page_language: locale,
        });
    }, [pageViewUri, parentPagePathWithoutParams, locale]);

    if (isFallback) {
        return <LoadingContentPageTemplate />;
    }

    switch (entry?.__typename) {
        case 'festivalContentPages_informationPage_Entry':
            return <InformationPage data={entry} />;
        case 'festivalContentPages_navigationPage_Entry':
            return <NavigationPage data={entry} />;
        case 'festivalContentPages_newsOverview_Entry':
            return <NewsArticlesOverviewPage data={entry} />;
        case 'festivalContentPages_programPage_Entry':
            return <ProgrammeOverviewPage data={entry} />;
        case 'festivalContentPages_guestbookPage_Entry':
            return <GuestlistOverviewPage data={entry} />;
        case 'festivalContentPages_pressAndIndustryPage_Entry':
            return <PressAndIndustryCollectionPage data={entry} />;
        case 'festivalContentPages_festivalCollectionPage_Entry':
            return <ProgrammeCollectionPage data={entry} />;
        default:
            // this shouldn't happen (should've been handled by getStaticProps already),
            // but still show an error page in case it does.
            return <Error statusCode={404} />;
    }
};

export type ContentPage = ContentPagesQuery['entry'];

export const getStaticPaths: GetStaticPaths = async () => {
    const data = await fetchCraftData<ContentPagePathsQuery, ContentPagePathsQueryVariables>({
        query: useContentPagePathsQuery.document,
    });
    return {
        paths: data.festivalContentPagesEntries.map(entry => ({
            params: {
                uri: entry.uri.split('/'),
                locale: getLocaleBySiteHandle(entry.siteHandle),
            },
        })),
        fallback: true,
    };
};

export const getRedirectSearch = (uri: string) => `siteHandle:festival path::${uri}`;

export const getStaticProps: GetStaticProps = async ctx => {
    const { params, locale, previewData } = ctx;
    const queryClient = new QueryClient();

    const uri = [...params.uri].join('/');
    const variables = {
        uri,
        site: siteHandleByLocale[locale],
        search: getRedirectSearch(uri),
    };
    const data = await queryClient.fetchQuery(useContentPagesQuery.getKey(variables), () =>
        fetchCraftData<ContentPagesQuery, ContentPagesQueryVariables>({
            variables,
            query: useContentPagesQuery.document,
            previewData: previewData as PreviewData,
        })
    );

    if (!data.entry) {
        const set = data.globalSets?.[0];
        if (set.__typename === 'redirects_GlobalSet') {
            const [redirect] = set.redirects;
            if (redirect) {
                return {
                    redirect: {
                        statusCode: 307,
                        destination: redirect.redirectUrl,
                    },
                    revalidate: DEFAULT_REVALIDATION_TIME,
                };
            }
        }

        return {
            notFound: true,
            revalidate: DEFAULT_REVALIDATION_TIME,
        };
    }

    if (data.entry.__typename === 'festivalContentPages_informationPage_Entry') {
        await prefetchContentBlocks(queryClient, data.entry.contentBlocks, locale);
    }
    await prefetchGlobals(queryClient, locale, { pathType: 'CURRENT_URI', uri });

    let result: ReturnType<GetStaticProps> = {
        props: {
            intlMessages: await loadIntlMessages(locale),
            dehydratedState: dehydrate(queryClient),
        },
        revalidate: DEFAULT_REVALIDATION_TIME,
    };
    // some pages require more data than just the data in their CMS entry.
    switch (data.entry.__typename) {
        case 'festivalContentPages_programPage_Entry':
            result = merge(result, await getProgrammeOverviewStaticProps(ctx));
            break;
        case 'festivalContentPages_newsOverview_Entry':
            result = merge(result, await getNewsOverviewStaticProps(ctx));
            break;
        case 'festivalContentPages_guestbookPage_Entry':
            result = merge(result, await getGuestlistStaticProps(ctx));
            break;
        case 'festivalContentPages_pressAndIndustryPage_Entry':
            result = merge(result, await getPressAndIndustryCollectionStaticProps(ctx));
            break;
        case 'festivalContentPages_festivalCollectionPage_Entry':
            result = merge(result, await getProgrammeCollectionPageStaticProps(ctx));
            break;
        default:
            break;
    }
    return result;
};

export default CatchAll;
