import { GuestbookFilterEnum } from '../gql/api';
import { defineMessages } from 'react-intl';

const guestlistFilterMessages = defineMessages<GuestbookFilterEnum>({
    [GuestbookFilterEnum.Attendance]: { defaultMessage: 'Aanwezigheid' },
    [GuestbookFilterEnum.Country]: { defaultMessage: 'Land' },
    [GuestbookFilterEnum.GoingTo]: { defaultMessage: 'Gaat naar', id: 'guestlist.filter.going-to' },
    [GuestbookFilterEnum.Profession]: { defaultMessage: 'Functie' },
    [GuestbookFilterEnum.Sector]: { defaultMessage: 'Sector' },
});

export default guestlistFilterMessages;
