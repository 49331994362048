import { FC } from 'react';
import { Body, Box, chakra, ChakraGrid, Heading, Img, TextButton } from 'designsystem';
import { useGetCmsImageProps } from 'shared';
import styled from '@emotion/styled';
import Link from 'next/link';
import { usePathwaysQuery } from '../../gql/cms';
import { FormattedMessage, useIntl } from 'react-intl';
import siteHandleByLocale from '../../utils/siteHandleByLocale';

const ProgrammePathways: FC = () => {
    const { locale } = useIntl();
    const { data } = usePathwaysQuery({ site: siteHandleByLocale[locale] });
    const pathways = data?.festivalPathwaysEntries;
    const getImgProps = useGetCmsImageProps();
    return (
        data && (
            <ChakraGrid gridTemplateColumns={['1fr', null, 'repeat(3, 1fr)']} gridGap={[6, null, 9]}>
                {pathways.map(pathway => (
                    <ChakraLink
                        href={`/${pathway.uri}`}
                        key={pathway.id}
                        aspectRatio="3 / 2"
                        display="flex"
                        alignItems="flex-end"
                        p={6}
                        position="relative"
                    >
                        <Background pos="absolute" top={0} right={0} bottom={0} left={0} zIndex={0}>
                            <Img
                                style={{ objectFit: 'cover' }}
                                fill
                                {...getImgProps(pathway.headerImage[0], pathway.title)}
                            />
                        </Background>
                        <Box zIndex={10}>
                            <Heading variant={4} color="neutralwhite." mb={2}>
                                {pathway.title}
                            </Heading>
                            <Box color="neutralwhite.">
                                <TextButton as="button">
                                    <Body fontSize={4}>
                                        <FormattedMessage defaultMessage="Bekijk pathway" id="pathway.cta" />
                                    </Body>
                                </TextButton>
                            </Box>
                        </Box>
                    </ChakraLink>
                ))}
            </ChakraGrid>
        )
    );
};

export default ProgrammePathways;

const ChakraLink = chakra(Link);

const Background = styled(Box)(
    ({ theme }) => `
    &:after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: linear-gradient(transparent, ${theme.tokens.ColorNeutralBlack});
        z-index: 1;
        opacity: 0.3333;
    }
`
);
