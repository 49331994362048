import { NumberParam, StringParam, useQueryParam, useQueryParams, withDefault } from 'use-query-params';
import { AtoZFilterEnum, useSearchAtoZFiltersQuery, useSearchAtoZHitsQuery } from '../gql/api';
import { useDebounce } from 'use-debounce';
import { AtoZFilterQueryParams, getFilterVariables, hitsByFirstLetterMap } from '../utils/searchProgrammeUtils';
import { useEffect, useMemo, useRef } from 'react';
import { useRouter } from 'next/router';
import { gtm, useRemoveQueryFilters } from 'shared';
import { useBrowserSize, browserSizes } from 'designsystem';

export const ITEMS_PER_PAGE = 30;
const PageParam = withDefault(NumberParam, 1);

const parseFilterTitle = (key: string) => (key.includes('|') ? key.split('|')[1] : key);

const useAlphabetSearch = () => {
    const { data: alphabetSearchFilters, refetch } = useSearchAtoZFiltersQuery({ filters: [] });
    const [queryParams, setQueryParams] = useQueryParams({ ...AtoZFilterQueryParams });
    const [searchQuery, setSearchQuery] = useQueryParam('searchQuery', withDefault(StringParam, ''));
    const [page, setPage] = useQueryParam('page', PageParam);
    const [debouncedQuery] = useDebounce(searchQuery, 400);
    const queryVariables = useMemo(() => getFilterVariables<AtoZFilterEnum>(queryParams), [queryParams]);
    const { locale } = useRouter();
    const prevLocale = useRef(locale);
    const removeQueryFilters = useRemoveQueryFilters(AtoZFilterQueryParams);

    useEffect(() => {
        if (prevLocale.current !== locale && refetch) {
            // refetch filters on lang switch
            refetch();
            prevLocale.current = locale;
            removeQueryFilters();
        }
    }, [locale, prevLocale, refetch, removeQueryFilters]);

    const dropdownFilters = useMemo(
        () =>
            alphabetSearchFilters?.searchAtoZ.filters.map(filterObj => ({
                ...filterObj,
                amounts: filterObj.amounts.sort((a, b) =>
                    parseFilterTitle(a.key).localeCompare(parseFilterTitle(b.key))
                ),
            })),
        [alphabetSearchFilters?.searchAtoZ.filters]
    );

    const activeFilters = useMemo(
        () => queryVariables.filter(filterVariable => filterVariable.value?.some(val => !!val)),
        [queryVariables]
    );

    const { data: alphabetSearchHits, isLoading } = useSearchAtoZHitsQuery({
        query: debouncedQuery,
        limit: ITEMS_PER_PAGE,
        offset: (page - 1) * ITEMS_PER_PAGE,
        filters: activeFilters,
    });
    const checkboxFilters = useMemo(
        () => alphabetSearchFilters?.searchAtoZ.filters.find(filterObj => filterObj.filter === AtoZFilterEnum.AToZType),
        [alphabetSearchFilters?.searchAtoZ.filters]
    );
    const hitsByFirstLetter = useMemo(
        () => hitsByFirstLetterMap(alphabetSearchHits?.searchAtoZ?.hits),
        [alphabetSearchHits?.searchAtoZ?.hits]
    );

    useEffect(() => {
        if (debouncedQuery !== '') {
            gtm.event('search_collection', {
                search_term: debouncedQuery,
                collection: 'festival',
            });
        }
    }, [debouncedQuery]);
    const browserSize = useBrowserSize();
    const breakpointMediumDown = browserSizes.indexOf(browserSize) <= browserSizes.indexOf('m');

    const queryFilterVariables = useMemo(() => getFilterVariables<AtoZFilterEnum>(queryParams), [queryParams]);
    const selectedFilters = useMemo(
        () => queryFilterVariables.filter(filterVariable => filterVariable.value?.some(val => !!val)),
        [queryFilterVariables]
    );

    const drawerFilters = alphabetSearchFilters?.searchAtoZ.filters ?? [];
    return {
        checkboxFilters,
        hits: hitsByFirstLetter,
        hitsLoading: isLoading,
        totalHits: alphabetSearchHits?.searchAtoZ?.totalHits,
        allFilters: alphabetSearchFilters?.searchAtoZ.filters,
        activeFilters: selectedFilters.filter(selectedFilter => selectedFilter.key !== AtoZFilterEnum.AToZType),
        drawerFilters: breakpointMediumDown
            ? drawerFilters
            : drawerFilters.filter(filterObj => filterObj.filter !== AtoZFilterEnum.AToZType),
        queryParams,
        queryVariables,
        dropdownFilters,
        searchQuery,
        currentPage: page,
        itemsPerPage: ITEMS_PER_PAGE,
        setQueryParams,
        setSearchQuery,
        setPage,
        removeQueryFilters,
    };
};

export default useAlphabetSearch;
