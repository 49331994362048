import { ScheduleFilterEnum } from '../gql/api';
import { defineMessages } from 'react-intl';

const scheduleFilterMessages = defineMessages<ScheduleFilterEnum>({
    [ScheduleFilterEnum.Day]: { defaultMessage: 'Dag' },
    [ScheduleFilterEnum.IsTalk]: { defaultMessage: 'Talk' },
    [ScheduleFilterEnum.CountriesOfProduction]: { defaultMessage: 'Productieland' },
    [ScheduleFilterEnum.IsVideoOnDemand]: { defaultMessage: 'Video on demand' },
    [ScheduleFilterEnum.Section]: { defaultMessage: 'Sectie' },
    [ScheduleFilterEnum.ShowType]: { defaultMessage: 'Show type' },
    [ScheduleFilterEnum.Tags]: { defaultMessage: 'Tag' },
    [ScheduleFilterEnum.AudienceType]: { defaultMessage: 'Publiekstype' },
    [ScheduleFilterEnum.Venue]: { defaultMessage: 'Locatie' },
    [ScheduleFilterEnum.Accessibility]: { defaultMessage: 'Toegankelijkheid', id: 'accessibility.heading' },
});

export default scheduleFilterMessages;
