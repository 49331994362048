import { Stack } from 'designsystem';
import { FC, PropsWithChildren } from 'react';

const ProgrammeListSection: FC<PropsWithChildren> = ({ children }) => (
    <Stack as="section" direction="column" spacing={6} mt={[7, null, null, 9]}>
        {children}
    </Stack>
);

export default ProgrammeListSection;
