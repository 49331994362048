import React, { ChangeEvent, FC, ReactNode, useCallback, useState } from 'react';
import {
    FilterSelectField,
    ScheduleFilterQueryParams,
    ScheduleSearchFilterQueryParams,
} from '../../utils/searchProgrammeUtils';
import {
    Body,
    Box,
    Button,
    FilterCheckbox,
    FilterTag,
    Flex,
    HStack,
    IconFilters,
    ProgrammeSearchInput,
    useDisclosure,
} from 'designsystem';
import { SetQuery } from 'use-query-params';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from '@emotion/styled';
import { FiltersDrawer, Filter, FilterModal, useRemoveQueryFilters, useSetQueryFilter } from 'shared';
import scheduleFilterMessages from '../../utils/scheduleFilterMessages';
import { ScheduleFilterEnum, SearchScheduleFiltersQuery } from '../../gql/api';
import showTypeFilterMessages, { ShowTypeMessageKey } from '../../utils/showTypeFilterMessages';
import camelCase from 'lodash.camelcase';
import useSearchSchedule from '../../hooks/useSearchSchedule';
import { useTheme } from '@emotion/react';

interface Props {
    selectFilters: FilterSelectField[];
    checkboxFilters?: SearchScheduleFiltersQuery['searchSchedule']['filters'][number];
    soldOutToggle: ReactNode;
    drawerFilters: Filter[];
    totalHits: number;
    activeFilters: { key?: ScheduleFilterEnum; value: string[] }[];
    setQueryFilters?: SetQuery<ScheduleFilterQueryParams>;
}

const ProgrammeScheduleFilters: FC<Props> = ({
    selectFilters,
    checkboxFilters,
    drawerFilters,
    activeFilters,
    soldOutToggle,
    totalHits,
    setQueryFilters,
}) => {
    const drawerProps = useDisclosure();
    const modalProps = useDisclosure();
    const [selectedFilter, setSelectedFilter] = useState<Filter | null>(null);
    const removeQueryFilters = useRemoveQueryFilters(ScheduleSearchFilterQueryParams);
    const { formatMessage } = useIntl();
    const { selectedFilters } = useSearchSchedule();
    const { tokens } = useTheme();
    const setQueryFilter = useSetQueryFilter(ScheduleSearchFilterQueryParams);

    const onModal = useCallback(
        (filter: Filter) => {
            setSelectedFilter(filter);
            modalProps.onOpen();
        },
        [modalProps]
    );

    const clearFilter = (key: ScheduleFilterEnum, value: string[], deleteValue: string) => {
        setQueryFilters({
            [key]: value.filter(val => val !== deleteValue),
        });
    };

    const isCheckboxChecked = (key: string) => {
        const selectedCheckboxFilters = selectedFilters.find(filter => filter.key === checkboxFilters.filter);
        return selectedCheckboxFilters?.value?.some(val => val === key);
    };

    return (
        <>
            <Box display={['none', null, null, 'block']}>
                <HStack mb={6} spacing={6}>
                    {checkboxFilters.amounts.map(({ key }) => (
                        <FilterCheckbox
                            key={key}
                            value={key}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                setQueryFilter(checkboxFilters.filter, key, e.target.checked, 'checkbox');
                            }}
                            isChecked={isCheckboxChecked(key)}
                        >
                            {formatMessage(showTypeFilterMessages[camelCase(key) as ShowTypeMessageKey])}
                        </FilterCheckbox>
                    ))}
                </HStack>
                <HStack my={6}>
                    <Box flexGrow={1}>
                        <ProgrammeSearchInput fields={selectFilters} />
                    </Box>
                    <Button rightIcon={<StyledIconFilters />} onClick={() => drawerProps.onOpen()}>
                        Filters
                    </Button>
                </HStack>
                <Flex justifyContent="space-between">
                    <HStack spacing={2} flexWrap="wrap">
                        {activeFilters.map(({ key, value }) => (
                            <>
                                {value.map(val => (
                                    <FilterTag key={val} onClear={() => clearFilter(key, value, val)}>
                                        {val.split('|')?.[1] ?? val}
                                    </FilterTag>
                                ))}
                            </>
                        ))}
                        {activeFilters.length > 0 && (
                            <FilterTag onClear={() => removeQueryFilters()}>
                                <FormattedMessage defaultMessage="Alles wissen" />
                            </FilterTag>
                        )}
                    </HStack>

                    <HStack>
                        <Body as="label" htmlFor="soldOut">
                            <FormattedMessage defaultMessage="Tickets beschikbaar" />
                        </Body>
                        {soldOutToggle}
                    </HStack>
                </Flex>
            </Box>
            <Box display={['block', null, null, 'none']}>
                <Button w="100%" rightIcon={<StyledIconFilters />} onClick={() => drawerProps.onOpen()}>
                    Filters
                </Button>
                {activeFilters.length > 0 && (
                    <HStack mt={6} flexWrap="wrap" spacing={2}>
                        {activeFilters.map(({ key, value }) => (
                            <>
                                {value.map(val => (
                                    <FilterTag key={val} onClear={() => clearFilter(key, value, val)}>
                                        {val}
                                    </FilterTag>
                                ))}
                            </>
                        ))}
                        {activeFilters.length > 0 && (
                            <FilterTag onClear={() => removeQueryFilters()}>
                                <FormattedMessage defaultMessage="Alles wissen" />
                            </FilterTag>
                        )}
                    </HStack>
                )}
            </Box>
            <FiltersDrawer
                {...drawerProps}
                queryParams={ScheduleSearchFilterQueryParams}
                filters={drawerFilters}
                mobileChildren={
                    <HStack
                        justifyContent="space-between"
                        py={4}
                        borderBottomStyle="solid"
                        borderBottomWidth={tokens.BorderWidthM}
                        borderBottomColor={tokens.BorderColorNeutralDefault}
                    >
                        <Body as="label" htmlFor="soldOut" fontWeight="xl">
                            <FormattedMessage defaultMessage="Tickets beschikbaar" />
                        </Body>
                        {soldOutToggle}
                    </HStack>
                }
                onModal={onModal}
                collectionFilterMessages={scheduleFilterMessages}
                totalHits={totalHits}
                closeLabel={<FormattedMessage defaultMessage="Close" description="Close modal" />}
                modal={
                    <FilterModal
                        {...modalProps}
                        filter={selectedFilter}
                        filterMessages={scheduleFilterMessages}
                        queryParams={ScheduleSearchFilterQueryParams}
                        closeLabel={<FormattedMessage defaultMessage="Close" description="Close modal" />}
                    />
                }
            />
        </>
    );
};
export const StyledIconFilters = styled(IconFilters)`
    // chakra overrides the width/height which is very cool and not annoying - so that's why this is needed.
    width: ${({ theme }) => theme.sizes[5]} !important;
    height: ${({ theme }) => theme.sizes[5]} !important;
`;

export default ProgrammeScheduleFilters;
