import {
    ArrayParam,
    BooleanParam,
    SetQuery,
    StringParam,
    useQueryParam,
    useQueryParams,
    withDefault,
} from 'use-query-params';
import {
    ScheduleFilterEnum,
    SearchScheduleFiltersQuery,
    useSearchScheduleFiltersQuery,
    useSearchScheduleHitsQuery,
} from '../gql/api';
import { ChangeEvent, useEffect, useMemo, useRef } from 'react';
import { browserSizes, Select, Switch, useBrowserSize } from 'designsystem';
import { FormattedMessage } from 'react-intl';
import {
    FilterSelectField,
    getClosestFutureDay,
    getFilterVariables,
    getHitsFromTime,
    hitsByHourMap,
    ScheduleFilterQueryParams,
    ScheduleSearchFilterQueryParams,
    selectFilterFields,
    sortByDateDesc,
    sortDesc,
} from '../utils/searchProgrammeUtils';
import { isToday, startOfHour } from 'date-fns';
import { useTheme } from '@emotion/react';
import { useRouter } from 'next/router';
import { Filter, gtm, useDateFormatter, useDeepEffect, useRemoveQueryFilters } from 'shared';

const getSelectFilterLabel = (fieldKey: ScheduleFilterEnum | 'time') => {
    switch (fieldKey) {
        case ScheduleFilterEnum.Day:
            return <FormattedMessage defaultMessage="Datum" />;
        case ScheduleFilterEnum.Venue:
            return <FormattedMessage defaultMessage="Locatie" />;
        case 'time':
            return <FormattedMessage defaultMessage="Tijd vanaf" />;
        default:
            return undefined;
    }
};

const getFilterSelectFields = (
    filters: SearchScheduleFiltersQuery['searchSchedule']['filters'],
    queryFilters: Record<string, string[]>,
    setQueryFilters: SetQuery<ScheduleFilterQueryParams>,
    resetTimeFilter: () => void,
    dateFormatter: ReturnType<typeof useDateFormatter>['dateFormatter']
): FilterSelectField[] | undefined => {
    if (filters?.length > 0) {
        return filters.map(filterObj => {
            const key = filterObj.filter;
            return {
                key,
                label: getSelectFilterLabel(key),
                select: (
                    <Select
                        border="none"
                        id={key}
                        fontWeight={600}
                        background="none"
                        onChange={e => {
                            if (key === 'DAY') {
                                resetTimeFilter();
                            }
                            setQueryFilters({ [key]: [e.target.value] });
                        }}
                        value={queryFilters[key]}
                    >
                        {key === 'VENUE' && (
                            <option value={[null]}>
                                <FormattedMessage defaultMessage="Alle locaties" />
                            </option>
                        )}
                        {filterObj.amounts.sort(key === 'DAY' ? sortByDateDesc : sortDesc).map(amount => (
                            //  FormattedDate causes hydration error w/o suppressHydrationWarning: https://nextjs.org/docs/messages/react-hydration-error#solution-3-using-suppresshydrationwarning
                            <option key={amount.key} value={amount.key} suppressHydrationWarning>
                                {key === 'DAY' ? dateFormatter(new Date(amount.key), 'DATE-MEDIUM') : amount.key}
                            </option>
                        ))}
                    </Select>
                ),
            };
        });
    }
    return undefined;
};

const getTimeField = (
    hours: string[],
    onChange: (e: ChangeEvent<HTMLSelectElement>) => void,
    selectedTime: string | null
): FilterSelectField => ({
    key: 'time',
    label: getSelectFilterLabel('time'),
    select: (
        <Select border="none" id="time" fontWeight={600} background="none" onChange={onChange} value={selectedTime}>
            <option value={['ALL']}>
                <FormattedMessage defaultMessage="Alle tijden" />
            </option>
            {hours.map(hour => (
                <option key={hour} value={hour}>
                    {hour}
                </option>
            ))}
        </Select>
    ),
});

const checkboxKeys = ['Public', 'Publiek', 'Industry', 'New Media', 'Nieuwe Media', 'Online'];
const getShowTypeFilters = (filters: SearchScheduleFiltersQuery['searchSchedule']['filters'][number]) => ({
    ...filters,
    amounts: filters.amounts.filter(amount => checkboxKeys.some(checkboxKey => checkboxKey === amount.key)),
});
const nonDrawerFilters = [ScheduleFilterEnum.Day, ScheduleFilterEnum.Venue, ScheduleFilterEnum.ShowType];

const getDrawerFilters = (filters: SearchScheduleFiltersQuery['searchSchedule']['filters']): Filter[] =>
    filters.map(filterObj => ({
        ...filterObj,
        inputType: filterObj.filter === 'DAY' ? 'radio' : 'checkbox',
    }));

const useSearchSchedule = () => {
    const { data: searchScheduleFilters, refetch } = useSearchScheduleFiltersQuery({ filters: [] });
    const initialDay = getClosestFutureDay(searchScheduleFilters?.searchSchedule.filters);
    const [queryFilters, setQueryFilters] = useQueryParams({
        ...ScheduleSearchFilterQueryParams,
        DAY: withDefault(ArrayParam, [initialDay]),
    });
    const removeQueryFilters = useRemoveQueryFilters(ScheduleSearchFilterQueryParams);
    const [{ hideSoldOut }, setHideSoldOut] = useQueryParams({ hideSoldOut: withDefault(BooleanParam, false) });
    const theme = useTheme();
    const { locale } = useRouter();
    const prevLocale = useRef(locale);
    const browserSize = useBrowserSize();
    const breakpointMediumDown = browserSizes.indexOf(browserSize) <= browserSizes.indexOf('m');
    const { timeFormatter, dateFormatter } = useDateFormatter();

    const queryFilterVariables = useMemo(() => getFilterVariables<ScheduleFilterEnum>(queryFilters), [queryFilters]);

    const selectedFilters = useMemo(
        () => queryFilterVariables.filter(filterVariable => filterVariable.value?.some(val => !!val)),
        [queryFilterVariables]
    );

    const isTodaySelected = selectedFilters?.some(
        selectedFilter => selectedFilter.key === ScheduleFilterEnum.Day && isToday(new Date(selectedFilter.value[0]))
    );

    const { data: searchScheduleHits, isLoading } = useSearchScheduleHitsQuery(
        {
            filters: selectedFilters,
        },
        {
            keepPreviousData: true,
        }
    );

    const hits = useMemo(
        () =>
            searchScheduleHits?.searchSchedule.hits
                .filter(hit => !hit.isOngoingProgram && !hit.isVideoOnDemand)
                .filter(hit => (hideSoldOut ? hit.ticketAvailabilityStatus !== 'SOLD_OUT' : true)),
        [hideSoldOut, searchScheduleHits?.searchSchedule.hits]
    );
    const hitsByHour = useMemo(() => hitsByHourMap(hits, timeFormatter), [hits, timeFormatter]);
    const defaultTime = useMemo(() => {
        const now = timeFormatter(startOfHour(startOfHour(new Date())), 'TIME-SHORT');
        if (isTodaySelected && Object.keys(hitsByHour ?? {}).includes(now)) {
            return now;
        }

        return 'ALL';
    }, [hitsByHour, isTodaySelected, timeFormatter]);

    const [selectedTime, setSelectedTime] = useQueryParam('time', withDefault(StringParam, defaultTime));

    useEffect(() => {
        if (prevLocale.current !== locale && refetch) {
            // refetch filters on lang switch
            refetch();
            prevLocale.current = locale;
            removeQueryFilters();
        }
    }, [locale, prevLocale, refetch, removeQueryFilters]);

    const soldOutToggle = (
        <Switch
            size="lg"
            id="soldOut"
            _checked={{ backgroundColor: theme.tokens.SyntaxPillarColorFestivalDefault }}
            onChange={() => setHideSoldOut({ hideSoldOut: !hideSoldOut })}
            isChecked={hideSoldOut}
        />
    );

    const selectFilters = useMemo(
        () =>
            getFilterSelectFields(
                searchScheduleFilters?.searchSchedule?.filters.filter(filterObj =>
                    selectFilterFields.some(fieldName => filterObj.filter === fieldName)
                ),
                queryFilters,
                setQueryFilters,
                () => setSelectedTime(null),
                dateFormatter
            ),
        [searchScheduleFilters?.searchSchedule?.filters, queryFilters, setQueryFilters, dateFormatter, setSelectedTime]
    );

    const checkboxFilters = useMemo(
        () =>
            getShowTypeFilters(
                searchScheduleFilters?.searchSchedule.filters.find(filterObj => filterObj.filter === 'SHOW_TYPE')
            ),
        [searchScheduleFilters?.searchSchedule.filters]
    );

    const activeFilters = useMemo(
        () => selectedFilters.filter(selectedFilter => !nonDrawerFilters.includes(selectedFilter.key)),
        [selectedFilters]
    );

    useDeepEffect(() => {
        for (const queryFilter of Object.keys(queryFilters) as Array<keyof typeof queryFilters>) {
            if (queryFilters[queryFilter].length > 0) {
                gtm.event('apply_filter', {
                    filter_name: queryFilter,
                    filter_value: queryFilters[queryFilter].join(', '),
                    collection: 'archive',
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedFilters]);

    const timeField = getTimeField(
        Object.keys(hitsByHour ?? {}),
        e => setSelectedTime(e.target.value === '' ? null : e.target.value),
        selectedTime
    );
    selectFilters.splice(1, 0, timeField);

    const hitsFromSelectedTime = useMemo(() => getHitsFromTime(hitsByHour, selectedTime), [hitsByHour, selectedTime]);

    const ongoingHits = useMemo(
        () =>
            searchScheduleHits?.searchSchedule.hits
                .filter(hit => hit.isOngoingProgram || hit.isVideoOnDemand)
                .filter(hit => (hideSoldOut ? hit.ticketAvailabilityStatus !== 'SOLD_OUT' : true)),
        [hideSoldOut, searchScheduleHits?.searchSchedule.hits]
    );

    const drawerFilters: Filter[] = getDrawerFilters(searchScheduleFilters?.searchSchedule.filters);

    return {
        selectFilters,
        selectedFilters,
        checkboxFilters,
        activeFilters: breakpointMediumDown
            ? selectedFilters.filter(selectedFilter => selectedFilter.key !== ScheduleFilterEnum.Day)
            : activeFilters,
        drawerFilters: breakpointMediumDown
            ? drawerFilters
            : drawerFilters.filter(filterObj => !nonDrawerFilters.includes(filterObj.filter as ScheduleFilterEnum)),
        soldOutToggle,
        hitsLoading: isLoading,
        hits: hitsFromSelectedTime ?? hitsByHour,
        ongoingHits,
        totalHits: searchScheduleHits?.searchSchedule.totalHits,
        queryFilters,
        isTodaySelected,
        selectedTime,
        setQueryFilters,
        initialDay,
    };
};

export default useSearchSchedule;
