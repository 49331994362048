import { useQueryClient } from '@tanstack/react-query';
import { useAddFilmToFavoritesMutation, useFavoriteFilmsQuery, useRemoveFilmFromFavoritesMutation } from '../gql/api';
import { useIsSigningIn, useProfileQuery, useSignIn } from 'shared';
import React, { useCallback } from 'react';
import { useSession } from 'next-auth/react';
import { Body, Note, useToast } from 'designsystem';
import { FormattedMessage } from 'react-intl';

const useToggleFilmFavorite = () => {
    const queryClient = useQueryClient();
    const toast = useToast();
    const { status } = useSession();
    const isProfileEnabled = status === 'authenticated';
    const { data: userData, isLoading: isLoadingProfile } = useProfileQuery(undefined, {
        enabled: isProfileEnabled,
    });

    const { mutate: addToFavorites, isLoading: isAdding } = useAddFilmToFavoritesMutation({
        onSuccess(response) {
            const updatedUserData = {
                ...userData,
                viewer: {
                    ...userData?.viewer,
                    ...response.addFilmToFavorites.user,
                },
            };
            queryClient.setQueryData(useProfileQuery.getKey(), updatedUserData);
            queryClient.setQueryData(useFavoriteFilmsQuery.getKey(), updatedUserData);
        },
    });

    const { mutate: removeFromFavorites, isLoading: isRemoving } = useRemoveFilmFromFavoritesMutation({
        onSuccess(response) {
            const updatedUserData = {
                ...userData,
                viewer: {
                    ...userData?.viewer,
                    ...response.removeFilmFromFavorites.user,
                },
            };
            queryClient.setQueryData(useProfileQuery.getKey(), updatedUserData);
            queryClient.setQueryData(useFavoriteFilmsQuery.getKey(), updatedUserData);
        },
    });
    const isSigningIn = useIsSigningIn();
    const { signIn } = useSignIn();
    const isLoading = isAdding || isRemoving || isSigningIn || (isProfileEnabled ? isLoadingProfile : false);

    const toggleFavorite = useCallback(
        (filmId: string) => {
            if (!isProfileEnabled) {
                if (!toast.isActive('not-logged-in')) {
                    toast({
                        id: 'not-logged-in',
                        render: () => (
                            <Note>
                                <Body>
                                    <strong>
                                        <FormattedMessage defaultMessage="Je bent niet ingelogd." />
                                    </strong>
                                </Body>
                                <Body>
                                    <FormattedMessage
                                        defaultMessage="<a>Log in</a> of <a>maak een account</a> aan om films toe te voegen aan je favorieten."
                                        values={{
                                            a: text => (
                                                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                                <a href="#" onClick={() => signIn()}>
                                                    {text}
                                                </a>
                                            ),
                                        }}
                                    />
                                </Body>
                            </Note>
                        ),
                        position: 'bottom-right',
                    });
                }
                return;
            }

            if (userData?.viewer?.favoriteFilms?.some(userFavorite => userFavorite.id === filmId)) {
                removeFromFavorites({ input: { filmId } });
                return;
            }
            addToFavorites({ input: { filmId } });
        },
        [isProfileEnabled, userData?.viewer?.favoriteFilms, addToFavorites, toast, signIn, removeFromFavorites]
    );

    const isFavorited = useCallback(
        (filmId: string) => userData?.viewer?.favoriteFilms?.some(userFavorite => userFavorite.id === filmId),
        [userData?.viewer?.favoriteFilms]
    );

    return {
        toggleFavorite,
        isFavorited,
        isLoadingFavorites: isLoading,
    };
};

export default useToggleFilmFavorite;
