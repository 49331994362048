// provide site handle by locale as 'site' variable to Craft CMS queries
const siteHandleByLocale: Record<string, string> = {
    nl: 'festivalNl',
    en: 'festivalEn',
};

export const getLocaleBySiteHandle = (siteHandle: string) => {
    const [locale] = [...Object.entries(siteHandleByLocale)].find(([, site]) => site === siteHandle);
    return locale;
};

export default siteHandleByLocale;
