import { SkeletonListItem } from 'designsystem';
import ProgrammeListSection from './ProgrammeListSection';
import { FC } from 'react';
import { ITEMS_PER_PAGE } from '../../hooks/useAlphabetSearch';

const ProgrammeListSkeleton: FC<{ itemsPerPage?: number }> = ({ itemsPerPage = ITEMS_PER_PAGE }) => (
    <ProgrammeListSection>
        {new Array(itemsPerPage).fill('').map((_, i) => (
            <SkeletonListItem key={`skeleton-${i}`} />
        ))}
    </ProgrammeListSection>
);

export default ProgrammeListSkeleton;
