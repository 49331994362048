import React, { FC } from 'react';
import { useTheme } from '@emotion/react';
import {
    cmsImageToOpenGraph,
    CollectionOverviewTemplate,
    fetchApiData,
    FormattedDateTimeRange,
    getMetaForFilmOrProjectHit,
    SEARCH_RESULTS_AMOUNT,
} from 'shared';
import { NumberParam, useQueryParam, withDefault } from 'use-query-params';
import { useSearchPressIndustryQuery } from '../../gql/api';
import { FormattedMessage } from 'react-intl';
import { Body } from 'designsystem';
import { ContentPage } from '../../pages/[...uri]';
import FestivalBreadcrumbs from '../FestivalBreadcrumbs';
import { dehydrate, QueryClient } from '@tanstack/react-query';
import { GetStaticProps } from 'next';
import loadIntlMessages from '../../utils/loadIntlMessages';

type PressIndustryPageData = Extract<ContentPage, { __typename: 'festivalContentPages_pressAndIndustryPage_Entry' }>;

const PressAndIndustryCollectionPage: FC<{ data: PressIndustryPageData }> = ({ data }) => {
    const theme = useTheme();
    const [page] = useQueryParam('page', withDefault(NumberParam, 1));
    const offset = (page - 1) * SEARCH_RESULTS_AMOUNT;
    const {
        data: apiData,
        isLoading,
        isError,
    } = useSearchPressIndustryQuery({
        offset,
        limit: SEARCH_RESULTS_AMOUNT,
    });
    return (
        <CollectionOverviewTemplate
            collectionPath="film"
            breadcrumbs={<FestivalBreadcrumbs />}
            pageTitle={data?.title}
            pageIntroText={data?.introText}
            pageOpenGraphImages={cmsImageToOpenGraph(data.navThumbnail)}
            heroBackgroundColor={theme.tokens.SyntaxBackgroundPrimaryDefault}
            hideSearch
            hits={apiData?.searchOfcFilms.hits.map(hit => ({
                id: hit.id,
                title: hit.fullPreferredTitle,
                image: hit.publications.favoriteImage,
                description: hit.intro?.translation,
                meta: getMetaForFilmOrProjectHit({ filmOrProject: hit }),
                note: hit.ofcStartOn && hit.ofcEndOn && (
                    <Body fontSize={3} mt={7}>
                        <FormattedMessage defaultMessage="Te bekijken voor geaccrediteerde professionals vanaf" />{' '}
                        {/* causes hydration error w/o suppressHydrationWarning: https://nextjs.org/docs/messages/react-hydration-error#solution-4-using-suppresshydrationwarning */}
                        <FormattedDateTimeRange
                            from={new Date(hit.ofcStartOn)}
                            to={new Date(hit.ofcEndOn)}
                            dateVariant="DATE-MEDIUM"
                            timeVariant="TIME-SHORT"
                        />
                    </Body>
                ),
            }))}
            isError={isError}
            totalHits={apiData?.searchOfcFilms.totalHits}
            isFetching={isLoading}
            totalPages={Math.ceil((apiData?.searchOfcFilms?.totalHits ?? 0) / SEARCH_RESULTS_AMOUNT)}
            offset={offset}
        />
    );
};

export const getStaticProps: GetStaticProps = async ({ locale }) => {
    const queryClient = new QueryClient();
    const variables = { offset: 0, limit: SEARCH_RESULTS_AMOUNT };
    await queryClient.prefetchQuery(useSearchPressIndustryQuery.getKey(variables), () =>
        fetchApiData({
            locale,
            query: useSearchPressIndustryQuery.document,
            variables,
        })
    );
    return {
        props: {
            dehydratedState: dehydrate(queryClient),
            intlMessages: await loadIntlMessages(locale),
        },
    };
};

export default PressAndIndustryCollectionPage;
