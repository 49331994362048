import { useQueryClient } from '@tanstack/react-query';
import { useAddShowToCalendarMutation, useRemoveShowFromCalendarMutation, useUserCalendarQuery } from '../gql/api';
import { useIsSigningIn, useProfileQuery, useSignIn } from 'shared';
import { useCallback } from 'react';
import { useSession } from 'next-auth/react';

const useToggleCalendar = () => {
    const queryClient = useQueryClient();
    const { status } = useSession();
    const { data: userData, isLoading: isLoadingProfile } = useProfileQuery(undefined, {
        enabled: status === 'authenticated',
    });

    const { mutate: addToCalendar, isLoading: isAdding } = useAddShowToCalendarMutation({
        onSuccess(response) {
            const updatedUserData = {
                ...userData,
                viewer: {
                    ...userData?.viewer,
                    ...response.addShowToCalendar.user,
                },
            };
            queryClient.setQueryData(useProfileQuery.getKey(), updatedUserData);
            queryClient.setQueryData(useUserCalendarQuery.getKey(), updatedUserData);
        },
    });

    const { mutate: removeFromCalendar, isLoading: isRemoving } = useRemoveShowFromCalendarMutation({
        onSuccess(response) {
            const updatedUserData = {
                ...userData,
                viewer: {
                    ...userData?.viewer,
                    calendar: {
                        ...userData?.viewer.calendar,
                        ...response.removeShowFromCalendar.user.calendar,
                    },
                },
            };
            queryClient.setQueryData(useProfileQuery.getKey(), updatedUserData);
            queryClient.setQueryData(useUserCalendarQuery.getKey(), updatedUserData);
        },
    });

    const isSigningIn = useIsSigningIn();
    const { signIn } = useSignIn();
    const isLoading = isAdding || isRemoving || isSigningIn || isLoadingProfile;

    const toggleCalendar = useCallback(
        (showId: string) => {
            if (status !== 'authenticated') {
                signIn();
            }
            return userData?.viewer?.calendar?.items?.some(
                calendarItem => calendarItem.__typename === 'Show' && calendarItem.id === showId
            )
                ? removeFromCalendar({ input: { showId } })
                : addToCalendar({ input: { showId } });
        },
        [status, userData?.viewer?.calendar, removeFromCalendar, addToCalendar, signIn]
    );

    const isAddedToCalendar = useCallback(
        (showId: string) =>
            status === 'authenticated' &&
            userData?.viewer?.calendar?.items?.some(
                calendarItem => calendarItem.__typename === 'Show' && calendarItem.id === showId
            ),
        [status, userData?.viewer?.calendar]
    );

    return {
        toggleCalendar,
        isAddedToCalendar,
        removeFromCalendar,
        isLoadingCalendar: isLoading,
    };
};

export default useToggleCalendar;
