import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { cmsImageToOpenGraph, InformationTemplate, SponsorsBanner, SponsorSet } from 'shared';
import { ContentPage } from '../../pages/[...uri]';
import FestivalBreadcrumbs from '../FestivalBreadcrumbs';

export type InformationPageData = Extract<ContentPage, { __typename: 'festivalContentPages_informationPage_Entry' }>;

const InformationPage: FC<{ data: InformationPageData }> = ({ data }) => {
    const {
        title,
        introText,
        contentBlocks,
        partnerLogos,
        partnerListings,
        partnerListingsText,
        navThumbnail,
        sponsorBannerSet,
    } = data;

    return (
        <InformationTemplate
            pageTitle={title}
            pageIntroText={introText}
            pageOpenGraphImages={cmsImageToOpenGraph(navThumbnail)}
            tableOfContentsTitle={<FormattedMessage defaultMessage="Table of Contents" />}
            contentBlocks={{
                contentBlocks,
            }}
            sponsorBanner={
                <SponsorsBanner
                    mt={[undefined, null, null, 17]}
                    mb={[10, null, null, undefined]}
                    sponsorSet={sponsorBannerSet?.[0] as SponsorSet}
                    maxW="368px"
                />
            }
            partners={{
                partnerListings,
                partnerListingsText,
                partnerLogos,
            }}
            breadcrumbs={<FestivalBreadcrumbs />}
        />
    );
};

export default InformationPage;
