import { Breadcrumb, Breadcrumbs } from 'designsystem';
import { useRouter } from 'next/router';
import { useBreadcrumbsQuery } from '../gql/cms';
import { ContentPageBreadcrumbs, ObjectPageBreadcrumbs } from 'shared';
import siteHandleByLocale from '../utils/siteHandleByLocale';
import { ComponentProps, useMemo } from 'react';

interface Props extends Omit<React.ComponentProps<typeof Breadcrumbs>, 'breadcrumbs' | 'homeBreadcrumb'> {
    parentUri?: string;
    currentPage?: Breadcrumb;
}

const FestivalBreadcrumbs: React.FC<Props> = ({ parentUri, currentPage, ...rest }) => {
    const { locale, query, asPath, pathname } = useRouter();
    const objectPage = useMemo(() => !!(parentUri && currentPage), [parentUri, currentPage]);
    const currentPageUri = Array.isArray(query.uri) ? query.uri.join('/') : query.uri;
    const uri = objectPage ? parentUri : currentPageUri;
    const { data } = useBreadcrumbsQuery(
        {
            site: siteHandleByLocale[locale],
            uri,
        },
        { enabled: !!uri }
    );

    const pathSplit = asPath.split('/').filter(part => part !== '');
    const pageTitle = pathSplit[pathSplit.length - 1].replaceAll('-', ' ');

    const breadcrumbsQueryResults = data?.currentPageInTree
        ? data
        : {
              currentPageInTree: {
                  id: pathname,
                  uri: asPath,
                  title: pageTitle,
                  siteHandle: siteHandleByLocale[locale],
                  ancestors: [],
              },
          };

    const sxStyles: ComponentProps<typeof ObjectPageBreadcrumbs>['sx'] &
        ComponentProps<typeof ContentPageBreadcrumbs>['sx'] = {
        '@media (max-width: 1570px)': {
            position: 'relative',
            top: [4, null, 8],
        },
    };

    return objectPage ? (
        <ObjectPageBreadcrumbs
            breadcrumbsQueryResults={breadcrumbsQueryResults}
            parentUri={parentUri}
            currentPage={currentPage}
            sx={sxStyles}
            {...rest}
        />
    ) : (
        <ContentPageBreadcrumbs breadcrumbsQueryResults={data} sx={sxStyles} {...rest} />
    );
};

export default FestivalBreadcrumbs;
