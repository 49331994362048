import { Body, Box, FestivalProgrammeHero, Heading, HtmlContent, IconDownload, TextButton } from 'designsystem';
import { FC } from 'react';
import {
    GuestbookOrderEnum,
    SearchGuestlistHitsQuery,
    SearchGuestlistHitsQueryVariables,
    SearchScheduleFiltersQuery,
    SearchScheduleFiltersQueryVariables,
    useGuestlistExportUrlQuery,
    useSearchGuestlistFiltersQuery,
    useSearchGuestlistHitsQuery,
} from '../../gql/api';

import { GetStaticProps } from 'next';
import { dehydrate, QueryClient } from '@tanstack/react-query';
import { fetchApiData, useSearchState } from 'shared';
import loadIntlMessages from '../../utils/loadIntlMessages';
import { ContentPage, DEFAULT_REVALIDATION_TIME } from '../../pages/[...uri]';
import FestivalBreadcrumbs from '../FestivalBreadcrumbs';
import useSearchGuestlist, { ITEMS_PER_PAGE } from '../../hooks/useSearchGuestlist';
import GuestlistSearch from '../GuestlistSearch';
import { useSession } from 'next-auth/react';
import { FormattedMessage } from 'react-intl';
import { SearchGuestlistFilterQueryParams } from '../../utils/searchProgrammeUtils';
import { NextSeo } from 'next-seo';
import striptags from 'striptags';

type GuestlistOverviewPageData = Extract<ContentPage, { __typename: 'festivalContentPages_guestbookPage_Entry' }>;

const GuestlistOverviewPage: FC<{ data: GuestlistOverviewPageData }> = ({ data }) => {
    const { status } = useSession();
    const searchState = useSearchState(SearchGuestlistFilterQueryParams);
    const { activeFilters } = useSearchGuestlist(searchState);
    const { data: guestlistExportUrl } = useGuestlistExportUrlQuery(
        { filters: activeFilters },
        // you only get a download url if logged in w/ correct role and filters applied
        { enabled: status === 'authenticated' && activeFilters?.length > 0 }
    );
    const downloadUrl = guestlistExportUrl?.searchGuestbook?.exportUrl;
    return (
        <>
            <NextSeo title={data?.title} description={striptags(data?.introText)} />
            <FestivalProgrammeHero
                topChildren={<FestivalBreadcrumbs />}
                downloadButton={
                    downloadUrl && (
                        <TextButton
                            href={downloadUrl}
                            iconLeft={
                                <Box w={6} mr={2}>
                                    <IconDownload />
                                </Box>
                            }
                            iconRight={null}
                            pb={5}
                        >
                            <Body fontWeight={600}>
                                <FormattedMessage defaultMessage="Download gastenlijst als .csv" />
                            </Body>
                        </TextButton>
                    )
                }
            >
                <Heading mt={['72px', null, '112px']} clamp={1} variant={1}>
                    {data?.title}
                </Heading>
                <Body mt={[4, null, 7]} pb={13}>
                    <HtmlContent html={data?.introText} />
                </Body>
            </FestivalProgrammeHero>
            <GuestlistSearch />
        </>
    );
};

export const getStaticProps: GetStaticProps = async ({ locale }) => {
    const queryClient = new QueryClient();
    const searchGuestlistVars: SearchGuestlistHitsQueryVariables = {
        filters: [],
        orderBy: GuestbookOrderEnum.FullNameAsc,
        query: '',
        limit: ITEMS_PER_PAGE,
        offset: 0,
    };

    await queryClient.prefetchQuery(
        [...useSearchGuestlistHitsQuery.getKey(searchGuestlistVars), { loggedIn: false }],
        () =>
            fetchApiData<SearchGuestlistHitsQuery, SearchGuestlistHitsQueryVariables>({
                query: useSearchGuestlistHitsQuery.document,
                variables: searchGuestlistVars,
                locale,
            })
    );

    await queryClient.prefetchQuery(useSearchGuestlistFiltersQuery.getKey(), () =>
        fetchApiData<SearchScheduleFiltersQuery, SearchScheduleFiltersQueryVariables>({
            query: useSearchGuestlistFiltersQuery.document,
            locale,
        })
    );

    return {
        props: {
            intlMessages: await loadIntlMessages(locale),
            dehydratedState: dehydrate(queryClient),
        },
        revalidate: DEFAULT_REVALIDATION_TIME,
    };
};

export default GuestlistOverviewPage;
