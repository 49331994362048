import { createEnumParam, StringParam, useQueryParam, withDefault } from 'use-query-params';
import {
    GuestbookFilterEnum,
    GuestbookOrderEnum,
    SearchGuestlistHitsDocument,
    SearchGuestlistHitsQuery,
    SearchGuestlistHitsQueryVariables,
    useSearchGuestlistFiltersQuery,
    useSearchGuestlistHitsQuery,
} from '../gql/api';
import { useMemo } from 'react';
import { getFilterVariables, getFirstLetter, SearchGuestlistFilterQueryParams } from '../utils/searchProgrammeUtils';
import groupBy from 'lodash.groupby';
import { SearchState, useApi } from 'shared';
import { useSession } from 'next-auth/react';
import { useQuery } from '@tanstack/react-query';

export const ITEMS_PER_PAGE = 10;
const OrderByParam = withDefault(
    createEnumParam([GuestbookOrderEnum.CompanyNameAsc, GuestbookOrderEnum.FullNameAsc]),
    GuestbookOrderEnum.FullNameAsc
);
const sortDesc = (a: string, b: string) => {
    const aTrimmed = a.trim();
    const bTrimmed = b.trim();
    if (aTrimmed < bTrimmed) {
        return -1;
    }
    if (aTrimmed > bTrimmed) {
        return 1;
    }
    return 0;
};

const useSearchGuestlist = (searchState: SearchState<typeof SearchGuestlistFilterQueryParams>) => {
    const { data: searchGuestlistFilters } = useSearchGuestlistFiltersQuery();
    const { debouncedQuery, queryFilters, page } = searchState;
    const [searchQuery, setSearchQuery] = useQueryParam('searchQuery', withDefault(StringParam, ''));
    const [orderBy, setOrderBy] = useQueryParam('orderBy', OrderByParam);

    const queryVariables = useMemo(() => getFilterVariables<GuestbookFilterEnum>(queryFilters), [queryFilters]);
    const activeFilters = useMemo(
        () => queryVariables.filter(filterVariable => filterVariable.value?.some(val => !!val)),
        [queryVariables]
    );
    const { status } = useSession();
    const variables = {
        query: debouncedQuery,
        limit: ITEMS_PER_PAGE,
        offset: (page - 1) * ITEMS_PER_PAGE,
        filters: activeFilters,
        orderBy,
    };
    const { data: searchGuestlistHits, isLoading } = useQuery(
        [...useSearchGuestlistHitsQuery.getKey(variables), { loggedIn: status === 'authenticated' }],
        useApi<SearchGuestlistHitsQuery, SearchGuestlistHitsQueryVariables>(SearchGuestlistHitsDocument).bind(
            null,
            variables
        )
    );

    const hitsByName = useMemo(
        () =>
            searchGuestlistHits?.searchGuestbook.hits &&
            groupBy(searchGuestlistHits?.searchGuestbook.hits, hit => getFirstLetter(hit.sortedFullName)),
        [searchGuestlistHits?.searchGuestbook.hits]
    );

    const hitsByCompanyName = useMemo(() => {
        if (searchGuestlistHits?.searchGuestbook.hits) {
            const hitsWithCompany = searchGuestlistHits?.searchGuestbook.hits
                .filter(hit => hit.companyName !== null)
                .sort((a, b) => sortDesc(a.companyName, b.companyName));

            const hitsWithoutCompany = searchGuestlistHits?.searchGuestbook.hits.filter(
                hit => hit.companyName === null
            );

            return groupBy([...hitsWithCompany, ...hitsWithoutCompany], hit =>
                hit.companyName ? getFirstLetter(hit.companyName) : 'noCompany'
            );
        }
        return undefined;
    }, [searchGuestlistHits?.searchGuestbook.hits]);

    const hits = useMemo(
        () => (orderBy === GuestbookOrderEnum.FullNameAsc ? hitsByName : hitsByCompanyName),
        [orderBy, hitsByName, hitsByCompanyName]
    );

    return {
        allFilters: searchGuestlistFilters?.searchGuestbook.filters,
        hits,
        hitsLoading: isLoading,
        totalHits: searchGuestlistHits?.searchGuestbook.totalHits,
        activeFilters,
        searchQuery,
        orderBy,
        currentPage: page,
        itemsPerPage: ITEMS_PER_PAGE,
        setOrderBy,
        setSearchQuery,
    };
};

export default useSearchGuestlist;
