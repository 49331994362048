import { FC } from 'react';
import GuestlistFilters from './GuestlistFilters';
import {
    Body,
    Box,
    ExtendedPersonCard,
    Flex,
    Heading,
    ListDivider,
    Meta,
    Note,
    Pagination,
    PersonListItem,
    Stack,
    VStack,
    Wrapper,
} from 'designsystem';
import useSearchGuestlist from '../hooks/useSearchGuestlist';
import { FormattedDateRange, isLoggedIn, useGetApiImageProps, useSearchState } from 'shared';
import { useSession } from 'next-auth/react';
import Link from 'next/link';
import { FormattedList, FormattedMessage } from 'react-intl';
import { SearchGuestlistFilterQueryParams, SearchGuestlistHits } from '../utils/searchProgrammeUtils';
import ProgrammeListSkeleton from './programme/ProgrammeListSkeleton';

const getBaseHitProps = (hit: SearchGuestlistHits[number], getImageProps: ReturnType<typeof useGetApiImageProps>) => ({
    name: hit.fullName,
    image: getImageProps(hit.publications.favoriteImage, hit.fullName),
    meta: (
        <Meta
            size="s"
            items={[
                hit.profession &&
                    // yes really
                    hit.profession.key !== 'not-set' && {
                        key: 'profession',
                        value: hit.profession.translation,
                    },
                hit.companyName && {
                    key: 'company',
                    value: hit.companyName,
                },
            ].filter(Boolean)}
        />
    ),
});

const GuestlistSearch: FC = () => {
    const searchState = useSearchState(SearchGuestlistFilterQueryParams);
    const { setPage } = searchState;
    const { hits, hitsLoading, totalHits, itemsPerPage, currentPage } = useSearchGuestlist(searchState);
    const getImageProps = useGetApiImageProps();
    const { status } = useSession();
    const loggedIn = isLoggedIn(status);

    return (
        <Wrapper my={9}>
            <GuestlistFilters />
            <VStack spacing={6} w="100%" alignItems="start" mt={[2, null, null, 0]}>
                {!hitsLoading &&
                    hits &&
                    Object.keys(hits).map((key, index) => (
                        <Box key={key} w="100%">
                            <Stack as="section" direction="column" spacing={6} mt={[7, null, null, 4]}>
                                {key !== 'noCompany' && <Heading variant={2}>{key.toUpperCase()}</Heading>}
                                {key === 'noCompany' && index !== 0 && <ListDivider mb={4} />}
                                {hits[key]?.length > 0 &&
                                    hits[key].map(hit => (
                                        <>
                                            {!loggedIn && <PersonListItem {...getBaseHitProps(hit, getImageProps)} />}
                                            {loggedIn && (
                                                <ExtendedPersonCard
                                                    {...getBaseHitProps(hit, getImageProps)}
                                                    lessInfoLabel="Less info"
                                                    moreInfoLabel="More info"
                                                    tableData={[
                                                        hit.films?.length > 0 && {
                                                            key: 'Films',
                                                            value: hit.films
                                                                .map(film => film.fullPreferredTitle)
                                                                .join(', '),
                                                        },
                                                        hit.email && {
                                                            key: 'Email',
                                                            value: (
                                                                <Link href={`mailto:${hit.email}`}>{hit.email}</Link>
                                                            ),
                                                        },
                                                        hit.accomodationStartsOn &&
                                                            hit.accomodationEndsOn && {
                                                                key: 'Attending',
                                                                value: (
                                                                    <>
                                                                        {hit.attendanceType && (
                                                                            <Box mr={1}>
                                                                                <Body>{hit.attendanceType}</Body>
                                                                            </Box>
                                                                        )}
                                                                        <Body>
                                                                            <FormattedDateRange
                                                                                from={
                                                                                    new Date(hit.accomodationStartsOn)
                                                                                }
                                                                                to={new Date(hit.accomodationEndsOn)}
                                                                                variant="DATE-SHORT"
                                                                            />
                                                                        </Body>
                                                                    </>
                                                                ),
                                                            },
                                                        hit.socials?.length > 0 && {
                                                            key: 'Social links',
                                                            value: (
                                                                <FormattedList
                                                                    type="unit"
                                                                    value={hit.socials?.map(social => (
                                                                        <Link
                                                                            key={social.title}
                                                                            href={social.value}
                                                                            target="_blank"
                                                                        >
                                                                            <Body mx={1}>{social.title}</Body>
                                                                        </Link>
                                                                    ))}
                                                                />
                                                            ),
                                                        },
                                                        hit.website && {
                                                            key: 'Website',
                                                            value: (
                                                                <Link
                                                                    href={hit.website.value}
                                                                    target="_blank"
                                                                    rel="noreferrer"
                                                                >
                                                                    {hit.website.title}
                                                                </Link>
                                                            ),
                                                        },
                                                        hit.companyProfile && {
                                                            key: 'Company profile',
                                                            value: hit.companyProfile,
                                                        },
                                                    ].filter(Boolean)}
                                                />
                                            )}
                                        </>
                                    ))}
                            </Stack>
                        </Box>
                    ))}
                {!hitsLoading && Object.keys(hits ?? {}).length === 0 && (
                    <Note w="100%">
                        <FormattedMessage defaultMessage="Geen gasten gevonden voor deze zoekopdracht" />
                    </Note>
                )}
                {hitsLoading && (
                    <Box w="100%">
                        <ProgrammeListSkeleton itemsPerPage={itemsPerPage} />
                    </Box>
                )}
                {!hitsLoading && totalHits > itemsPerPage && (
                    <Flex justifyContent="center" mt={[6, null, 9]} alignSelf="center">
                        <Pagination
                            currentPage={currentPage - 1}
                            setCurrentPage={(p: number) => {
                                window.scrollTo({
                                    top: 0,
                                    left: 0,
                                    behavior: 'smooth',
                                });
                                setPage(p + 1);
                            }}
                            totalPages={Math.ceil(totalHits / itemsPerPage)}
                        />
                    </Flex>
                )}
            </VStack>
        </Wrapper>
    );
};

export default GuestlistSearch;
